











// @ is an alias to /src
import Article, { HeaderArticle, TextImageArticle, VideoArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MediaTools from '@/libs/MediaTools';
import Block from './Block.vue';
import { isMobile } from '@/libs/Device';

@Component({})
export default class Video extends Block {
    @Prop() article!: VideoArticle;

    private get MediaTools(): MediaTools {
		return MediaTools;
	}

    public get mobile(): boolean {
        return isMobile.any() !== null;
    }
}

