












































// @ is an alias to /src
import Article, { WorkshopsArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from './Block.vue';
import Swoosh from '@/components/Swoosh.vue';
import { isMobile } from '@/libs/Device';

@Component({
    components: {
        Swoosh
    }
})
export default class Workshops extends Block {

    private get mobile(): boolean {
        return isMobile.any() !== null;
    }

}

