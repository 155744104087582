// NPM
import Vue from 'vue';


// Libs
import FetchJSON from "./libs/FetchJSON";

// Vue imports
import App from './App.vue';
import Page from '@/views/Page.vue';

import Content from './types/Content';
import VueRouter, { RouteConfig } from 'vue-router';

require('@/sass/global.scss');

Vue.use(VueRouter);

const contentUri: string = './content.json';
FetchJSON(contentUri).then((contentJson) => {
    const content: Content = contentJson as Content;
    const routes: Array<RouteConfig> = [];

    content.views.forEach(view => {
        const route: RouteConfig = {
            path: '/',
            name: view.title,
            component: Page,
            props: {
                view
            }
        };

        routes.push(route);
    });

    const router = new VueRouter({
        routes, 
        mode: 'hash'
    });

    const v = new Vue({
        created: () =>
        {
            console.log('Created: ', routes);
        },
        router, 
        render: h=> h(App, {
            props: {
                content,
            }
        })
    }).$mount('#app')
    
});


