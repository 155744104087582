























// @ is an alias to /src
import Article, { HeaderArticle, TextImageArticle } from '@/types/Article';
import SwooshGraphic from '@/types/SwooshGraphic';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {isMobile} from '@/libs/Device';

@Component({})
export default class Swoosh extends Vue {
    @Prop() swoosh!: SwooshGraphic;

    private get isMobile(): boolean {
        return isMobile.any() !== null;
    }

    private get transform(): string | undefined {
        return (!this.isMobile) ? this.swoosh.transform : this.swoosh.mobileTransform;
    }
    private get width(): string | undefined {
        return (!this.isMobile || this.swoosh.mobileWidth === undefined) ? this.swoosh.width : this.swoosh.mobileWidth;
    }
}

