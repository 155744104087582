















// @ is an alias to /src
import { isMobile } from '@/libs/Device';
import Article, {  TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Footer extends Vue {
    @Prop() article!: TextImageArticle;

    public get mobile(): boolean {
        return isMobile.any() !== null;
    }
}

