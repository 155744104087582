import FetchJSON from '@/libs/FetchJSON';

export enum VideoType {
	File,
	YouTube,
	Vimeo
}

export default class MediaTools {
	public static getVideoType(videoUrl: string): VideoType {
		if (videoUrl.indexOf('youtube') > -1 || videoUrl.indexOf('youtu.be') > -1) {
			return VideoType.YouTube;
		} else if(videoUrl.indexOf('vimeo') > -1) {
			return VideoType.Vimeo;
		} else {
			return VideoType.File;
		}
	}

	public static getVideoThumb(videoUrl: string, thumbImage: string | undefined): string | undefined {

		if (MediaTools.isYouTube(videoUrl) && thumbImage === undefined) {
			return MediaTools.getYouTubeThumbnail(videoUrl);
		} else {
			if (!MediaTools.isFile(videoUrl)) {
				console.warn('Couldn\'t find video thumbnail for: ' + videoUrl);
			}
			return thumbImage as string;
		}
		
	}

	public static isFile(videoUrl: string): boolean {
		return MediaTools.getVideoType(videoUrl) === VideoType.File;
	}

	public static isYouTube(videoUrl: string): boolean {
		return MediaTools.getVideoType(videoUrl) === VideoType.YouTube;
	}

	public static isVimeo(videoUrl: string): boolean {
		return MediaTools.getVideoType(videoUrl) === VideoType.Vimeo;
	}

	public static getYouTubeSrc(videoUrl: string): string {
		if (MediaTools.isYouTube(videoUrl)) {

			let youtubeStr = videoUrl;
			if (youtubeStr.indexOf('watch?') > -1) {
				youtubeStr = MediaTools.getYoutubeVideoIdFromUrl(youtubeStr);
				youtubeStr = 'https://www.youtube.com/embed/' + youtubeStr + '?autoplay=1&cc_load_policy=1&modestbranding=1&iv_load_policy=3&controls=1&rel=0'
			} else if (youtubeStr.indexOf('embed') === -1) {
				console.warn('Error trying to build YouTube string: ', youtubeStr);
				return '';
			}

			return youtubeStr;
		}
		return '';
		
	}

	public static getVimeoSrc(videoUrl: string): string {
		return 'https://player.vimeo.com/video/' + MediaTools.getVimeoIdFromUrl(videoUrl) + '?autoplay=1&color=ffffff&byline=0&portrait=0&badge=0';
	}

	public static getYoutubeVideoIdFromUrl(videoUrl: string): string {
		return videoUrl.split('=')[1].split('&')[0];
	}

	public static getVimeoIdFromUrl(videoUrl: string): string {
		const videoSplit: string[] = videoUrl.split('/');
		return videoSplit[videoSplit.length - 1].split('?')[0];
	}

	public static convertVimeoThumbnailToSize(vimeoThumbnail: string, size: string): string {
		return vimeoThumbnail.split('_')[0] + '_' + size + '.jpg';
	}

	public static getYouTubeThumbnail(videoUrl: string, thumbnailType: number = 0) {
		// https://img.youtube.com/vi/<insert-youtube-video-id-here>/0.jpg
		// Get the youtube video id
		const youtubeVidId = MediaTools.getYoutubeVideoIdFromUrl(videoUrl);
		return 'https://img.youtube.com/vi/' + youtubeVidId + '/' + thumbnailType.toString() + '.jpg';

	}

	public static hasWebcam(): boolean {
		return (navigator.mediaDevices && navigator.mediaDevices.getUserMedia !== undefined);
	}

}
