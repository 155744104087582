















// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isMobile } from '@/libs/Device';

@Component({})
export default class Header extends Vue {
    @Prop() article!: HeaderArticle;

    private get isMobile(){
        return isMobile.any();
    }
}
