































// @ is an alias to /src
import Article, {  ContactArticle, TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from './Block.vue';
import Swoosh from '@/components/Swoosh.vue';
import { isMobile } from '@/libs/Device';

@Component({
    components: {
        Swoosh
    }
})
export default class Contact extends Block {
    @Prop() article: ContactArticle = this.article;

    private get mobile(): boolean {
        return isMobile.any() !== null;
    }
}

