





















// @ is an alias to /src
import Article, { HeaderArticle, TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Swoosh from '@/components/Swoosh.vue';
import Block from './Block.vue';
import { isMobile } from '@/libs/Device';

@Component({
    components: {
        Swoosh,
    }
})
export default class TextImage extends Block {
    @Prop() article: TextImageArticle = this.article;

    public get mobile(): boolean {
        return isMobile.any() !== null;
    }
}

